<template>
  <Sticky>
    <!-- <div class="field-header">
      <div class="name">生物标记物｜研究信息｜出版信息</div>
      <Icon class-prefix="iconfont" name="shaixuan" size="16" color="#fff" @click='filterHandle' />
    </div> -->
    <div class="search-type">
      <div class="label">{{ retrieval }}</div>
      <div class="subscribe-wrap" @click="handleSubscribe">
        <div class="subscribe">订阅</div>
      </div>
      <Icon class-prefix="iconfont" name="shaixuan" size="16" color="#0C86FE" @click='filterHandle' />
    </div>
    <div class="bar"></div>
  </Sticky>
  <div class="list-wrap" v-if="!showDashboard">
    <ListHead @on-change="changeHandle" :total="state.count" @on-click="dashboardHandle" />
    <van-list
      ref="myList"
      v-model:loading="state.loading"
      :finished="state.finished"
      :finished-text="state.list.length ? '没有更多了' : ''"
      offset="20"
      @load="onLoad"
    >
      <Item  v-for="item in state.list" :key="item" :detail="item" @on-click="itemClickHandle" />
    </van-list>
    <Empty v-if="state.list.length <= 0 && state.finished" />
  </div>
  <div v-else>
    <DashBoard :filter="dashboardFilter" :retrieval="retrieval" @on-click="listHandle" />
  </div>
  <BackTop />
  <Popup
    v-model:show="show"
    round
    position="bottom"
    :style="{ height: '65%' }"
  >
    <Filter
      :list="state.filterList"
      :total="state.total"
      @on-reset="resetHandle"
      @on-ok="okHandle"
      @on-change="filterChange"
    />
  </Popup>
</template>

<script>
import { computed, onActivated, reactive, ref } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { List, Sticky, Icon, Popup, Toast } from 'vant'
import Empty from '@/components/empty/'
import ListHead from './components/ListHead'
import Item from '@/components/item/'
import BackTop from '@/components/backTop/'
import { getFieldLiteratures, getFieldTags, getAticleCount, subscribeApi } from '@/api/'
import { deleteObjEmptyData } from '@/utils/'
import Filter from './components/Filter'
import DashBoard from '@/views/dashboard/'
export default {
  name: 'advanceList',
  components: {
    'van-list': List,
    Empty,
    Sticky,
    Icon,
    ListHead,
    Item,
    BackTop,
    Popup,
    Filter,
    DashBoard
  },
  setup () {
    // document.title = '文献列表'
    const state = reactive({
      list: [],
      loading: false,
      finished: false,
      count: 0,
      total: 0,
      page: 0,
      publish_year_asc: '',
      impact_factor_asc: '',
      relevance_asc: 0,
      filterList: [],
      filter: null,
      impactFactor: '',
      apiFilter: null,
      filterItem: ''
    })
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const show = ref(false)
    const isFieldEmpty = ref(true)
    const showDashboard = ref(false)
    const myList = ref(null)

    const retrieval = computed(() => {
      if (route.query.content) {
        return route.query.content
      } else {
        return route.query.search ? `${route.query.search.replace(/,/g, ' ')}` : ''
      }
    })

    const dashboardFilter = computed(() => {
      if (route.query.content) {
        return {
          ...JSON.parse(route.query.search),
          ...state.filter
        }
      } else {
        return {
          ...state.filter,
          bio_expression: route.query.search
        }
      }
    })

    const subscribedHandle = (obj) => {
      state.filterItem = Object.values(obj).map(item => item).join(',').replace(/,/g, ' And ')
    }

    const handleSubscribe = () => {
      subscribeApi({
        bio_expression: retrieval.value.replace(/ /g, ','),
        is_subscribed: '1'
      }).then(res => {
        if (res.code === 200) {
          Toast.success('订阅成功')
        }
      })
    }

    // 获取筛选项
    const fetchTags = () => {
      let params = {}
      if (route.query.content) {
        params = {
          ...JSON.parse(route.query.search)
        }
      } else {
        params.bio_expression = route.query.search
      }
      getFieldTags(params).then(res => {
        state.apiFilter = res.data
        const language = {
          title: '语言类型',
          key: 'language__in',
          isMultiply: true,
          lables: res.data.language.map(item => ({
            name: item.name,
            value: item.name
          }))
        }
        const articleType = {
          title: '文献类型',
          key: 'article_type__id__in',
          isMultiply: true,
          lables: res.data.article_type.map(item => ({
            name: item.name,
            value: item.id
          }))
        }
        const publishDate = {
          title: '发表年份',
          key: 'publish_year__range',
          type: 'slide',
          lables: res.data.publish_year
        }
        state.impactFactor = res.data.impact_factor
        if (JSON.stringify(deleteObjEmptyData(res.data)) === '{}') {
          isFieldEmpty.value = false
        }
        state.filterList.push(language, articleType, publishDate)
      })
    }
    fetchTags()

    const resetState = () => {
      state.list = []
      state.loading = false
      state.finished = false
      state.page = 0
    }
    // 获取文献列表
    const fetchLiteratures = () => {
      let params = deleteObjEmptyData({
        page: state.page,
        relevance_asc: state.relevance_asc,
        publish_year_asc: state.publish_year_asc,
        impact_factor_asc: state.impact_factor_asc,
        ...state.filter
      })
      if (route.query.content) {
        params = {
          ...params,
          ...JSON.parse(route.query.search)
        }
      } else {
        params.bio_expression = route.query.search
      }
      getFieldLiteratures(params).then(res => {
        if (res.code === 200) {
          console.log(res)
          state.list.push(...res.data.data.data)
          state.count = res.data.page.count
          state.total = res.data.page.count
          state.loading = false
          if (state.list.length >= state.count) {
            state.finished = true
          }
        }
      })
    }
    // 分页加载
    const onLoad = () => {
      state.page++
      fetchLiteratures()
    }

    const filterHandle = () => {
      if (isFieldEmpty.value) {
        show.value = true
      }
    }
    // 排序
    const changeHandle = (item) => {
      if (item.key === 'relevance_asc') {
        state.publish_year_asc = ''
        state.impact_factor_asc = ''
        state.relevance_asc = item.value
      } else if (item.key === 'publish_year_asc') {
        state.relevance_asc = ''
        state.impact_factor_asc = ''
        state.publish_year_asc = item.value
      } else {
        state.relevance_asc = ''
        state.publish_year_asc = ''
        state.impact_factor_asc = item.value
      }
      resetState()
      myList.value.check()
    }

    const resetHandle = (current, currentName) => {
      if (current.impact_factor__range === state.impactFactor.join(',')) {
        delete current.impact_factor__range
      }
      if (currentName.language__in) {
        delete currentName.language__in
      }
      if (currentName.article_type__id__in) {
        delete currentName.article_type__id__in
      }
      subscribedHandle(currentName)
      state.filter = current
      // show.value = false
      resetState()
      if (!showDashboard.value) {
        myList.value.check()
      }
    }

    const okHandle = (current, currentName) => {
      if (current.impact_factor__range === state.impactFactor.join(',')) {
        delete current.impact_factor__range
      }
      if (currentName.language__in) {
        delete currentName.language__in
      }
      if (currentName.article_type__id__in) {
        delete currentName.article_type__id__in
      }
      subscribedHandle(currentName)
      state.filter = current
      show.value = false
      resetState()
      if (!showDashboard.value) {
        myList.value.check()
      }
    }

    const itemClickHandle = (item) => {
      router.push(`/detail?id=${item.id}`)
    }
    // 筛选项变化时
    const filterChange = (current) => {
      if (current.impact_factor__range === state.impactFactor.join(',')) {
        delete current.impact_factor__range
      }
      let params = {
        ...current
      }
      if (route.query.content) {
        params = {
          ...params,
          ...JSON.parse(route.query.search)
        }
      } else {
        params.bio_expression = route.query.search
      }
      getAticleCount(params).then(res => {
        if (res.code === 200) {
          state.total = res.data.total
        }
      })
    }

    // 切换dashboard
    const dashboardHandle = () => {
      showDashboard.value = true
    }
    // 切换列表模式
    const listHandle = () => {
      showDashboard.value = false
    }

    onActivated(() => {
      // document.title = '文献列表'
    })

    onBeforeRouteLeave((to, from) => {
      if (to.path === '/dashboardList' || to.path === '/detail') {
        store.commit('catchComponents/GET_CATCHE_COMPONENTS', ['advanceList'])
      } else {
        store.commit('catchComponents/GET_CATCHE_COMPONENTS', [])
      }
    })

    return {
      state,
      onLoad,
      show,
      showDashboard,
      retrieval,
      myList,
      handleSubscribe,
      changeHandle,
      filterHandle,
      okHandle,
      resetHandle,
      itemClickHandle,
      filterChange,
      dashboardHandle,
      listHandle,
      dashboardFilter
    }
  }
}
</script>

<style lang="less" scoped>
.field-header {
  height: 40px;
  background: #0C86FE;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .name {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 14px;
  }
}
.search-type {
  padding: 14px 15px 14px 16px;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .label {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    display: flex;
    align-items: center;
  }
  .subscribe-wrap {
    margin-left: 17px;
    display: flex;
    align-items: center;
    .subscribe{
      width: 54px;
      height: 28px;
      border-radius: 4px;
      border: 1px solid #0C86FE;
      font-size: 14px;
      font-weight: 400;
      color: #0C86FE;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.bar {
  height: 12px;
  background: #F5F7FA;
}
</style>
